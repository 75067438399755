import http from "../../node_modules/fe-infrastractures/src/utils/http";

export const sendUserName = (data) => {
  return http.post("anon/login/actions/send-username", data);
};

export const sendForgetUsernameEmail = (data) => {
  return http.post("anon/login/actions/send-forget-username", data, { useBody: true });
};


export const verify = (data) => {
  return http.post("anon/login/actions/verify", data);
};

export const changePassWord = (data) => {
  return http.post("anon/login/actions/update-password", data);
};
export const changePasswordVerifyLinkDead = (data) => {
  return http.post("anon/login/actions/forget-password-verify-code", data);
};

export const changePassWordWithCode = (data) => {
  return http.post("anon/login/actions/update-password-with-code", data);
};

export const changeReserPasswordVerifyCode = (data) => {
  return http.post("anon/login/actions/reser-password-verify-code", data);
};

export const desc = () => {
  return http.get("anon/login/actions/desc", {});
};

export const successDesc = () => {
  return http.get("anon/login/actions/success-desc", {});
};

export const getPageDesc = (pageName) => {
  return http.post("anon/portal-page/setting", { pageName: pageName }, { useBody: true });//portal-page/setting
};

//UseCodeSendMethodEmail：true，UseCodeSendMethodSMS：false
export const getSendMethodItems = () => {
  return http.get("anon/login/actions/sendcode-method", {});
};


