import http from "../../node_modules/fe-infrastractures/src/utils/http";
import { globalEnv } from "fe-infrastractures";

export const getMyProfileInfo = (data) => {
  return http.get("/api/myprofile/" + data, {}, { loading: false, baseURL: globalEnv.VUE_APP_STD_URL });
};

export const saveMyProfileInfo = (data) => {
  return http.post("/api/myprofile/actions/update-user", data, { useBody: true, baseURL: globalEnv.VUE_APP_STD_URL });
};

export const getChangePasswordDesc = () => {
  return http.get("", {}, { loading: false });
};

export const saveChangePassword = (data) => {
  return http.post("/api/myprofile/actions/update-password", data, { baseURL: globalEnv.VUE_APP_STD_URL });
};

export const checkPassword = (data) => {
  return http.post("/api/myprofile/actions/check-password", data, { loading: false });
};

export const changePhoto = (data) => {
  return http.post("/api/myprofile/actions/update-user-photo", data, { loading: false });
};

export const getYearOfBirthOptions = (data) => {
  return http.get("/anon/home/yearofbirth", data, { loading: true });
};

export const getPreferredpronousList = (id) => {
  return http.get(`/api/myprofile/actions/getpreferredpronousdropmodel/${id}`, null, {});
};