<template>
  <div class="layoutContent noNavTabCard">
    <div class="layoutCard">
      <k-alert-msg ref="lblMsg" />
      <home-title
        :description="description"
        class="desWap"
        :showpic="
          getConfigVal(curPageConfigAndDefaultPageConfig, 'showPic', 'vIf')
        "
      />
      <KValidationObserver ref="observer" class="contentWap d-block">
        <div class="cardHead">{{ $g("changePassword") }}</div>
        <k-form
          @keydown.enter="isPrevent"
          @submit.prevent="saveForm"
          class="pt-3"
        >
          <k-form-group
            label-class="require-mark"
            label-suffix="currentPassword"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="noneBind"
          >
            <KValidationProvider
              :rules="{ required: true, remote: checkPasswordUsed, max: 256 }"
              v-slot="{ errors }"
              slim
              name="currentPassword"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-input
                id="currentPassword"
                v-model="form.currentPassword"
                type="password"
                :aria-label="$g('currentPassword')"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            label-class="require-mark"
            label-suffix="newPassword"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="noneBind"
          >
            <KValidationProvider
              rules="required|password"
              v-slot="{ errors }"
              slim
              vid="newPassword"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-input
                v-model="form.newPassword"
                type="password"
                :aria-label="$g('newPassword')"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            label-class="require-mark"
            label-suffix="confirmPassword"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="noneBind"
          >
            <KValidationProvider
              :rules="{
                required: true,
                max: 256,
                confirmpassword: '@newPassword',
              }"
              v-slot="{ errors }"
              slim
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-input
                id="confirmPassword"
                v-model="form.confirmPassword"
                @paste.native.capture.prevent="() => false"
                type="password"
                :aria-label="$g('confirmPassword')"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="noneBind"
            style="display: block; margin-top: 25px"
            class="wapBtnCenter"
          >
            <button type="submit" class="btn btnPrimary w-150">
              {{ $g("save") }}
            </button>
          </k-form-group>
        </k-form>
      </KValidationObserver>
    </div>
  </div>
</template>
<script>
import { saveChangePassword, checkPassword } from "@/api/myprofile";
import {
  repResult,
  storeage,
  saveAlert,
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
  isPrevent,
} from "fe-infrastractures";
import { getPageDesc } from "@/api/login";
import { pageNameEnum } from "@/utils/enums/pageNameEnum";
import HomeTitle from "@/views/components/homeTitle";
export default {
  components: { HomeTitle },
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    return {
      isPrevent,
      curPageConfigAndDefaultPageConfig,
      getConfigVal,
      description: "",
      tabIndex: 1,
      form: {
        id: storeage.getUserInfo()?.userId,
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
    };
  },
  mounted() {
    this.showAlert();
    getPageDesc(pageNameEnum.MyProfileChangePassword).then((resp) => {
      this.description = resp.data.description;
    });
  },
  methods: {
    showAlert() {
      let status = saveAlert.savedStatus(this.$route);
      if (
        status == repResult.success ||
        this.$route.params.status == repResult.success
      ) {
        if (saveAlert.savedMessage(this.$route)) {
          this.$refs.lblMsg.message(
            status,
            saveAlert.savedMessage(this.$route)
          );
        } else {
          this.$refs.lblMsg.message(status, this.$route.params.message);
        }
      }
    },

    async checkPasswordUsed() {
      let result = null;
      let data = {
        id: storeage.getUserInfo()?.userId,
        password: this.form.currentPassword,
      };
      await checkPassword(data).then((rep) => {
        result = rep;
      });
      return result;
    },

    saveResponseFunc(m) {
      if (m.data.status == repResult.success) {
        this.$router.push({
          name: "my-profile",
          query: { redirectName: "change-password", tabIndex: 1 },
          params: {
            message: m.message,
            status: m.data.status,
          },
        });
      } else {
        this.$refs.lblMsg.message(m.data.status, m.message);
      }
    },
    async saveForm() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        let pmOpt = this.$confirm({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.save"),
          cancelType: "secondary",
          okText: this.$g("const.confirm.saveYes"),
          cancelText: this.$g("const.confirm.no"),
        });
        if (pmOpt == null) {
          return;
        }
        pmOpt.then(() => {
          saveChangePassword(this.form).then((m) => {
            this.saveResponseFunc(m);
          });
        });
      }
    },
  },
};
</script>
