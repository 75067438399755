export const pageNameEnum = {
    TrainingHistory: "TrainingHistory",
    TrainingClassNoData: "TrainingClass-NoData",
    Login: "Login",
    WorkAssignmentDetail: "WorkAssignment-Detail",
    ApplicationSubmitSuccessful: "Application-SubmitSuccessful",
    TrainingMaterialNoData: "TrainingMaterial-NoData",
    ApplicationPreview: "Application-Preview",
    ApplicationPreviewTermsAndConditions: "Application-Preview-TermsAndConditions",

    TrainingClassOnsiteTraining: "TrainingClass-OnsiteTraining",

    TrainingClassVirtualTraining: "TrainingClass-VirtualTraining",

    ApplicationBasicInfo: "Application-BasicInfo",

    ApplicationTermsAndConditions: "Application-TermsAndConditions",

    ApplicationSearchVoter: "Application-SearchVoter",

    TrainingClassList: "TrainingClass-List",

    ForgotPasswordSubmitSuccessful: "ForgotPassword-SubmitSuccessful",

    ApplicationLoadingPage: "Application-LoadingPage",

    MyProfileChangePassword: "MyProfile-ChangePassword",

    TrainingClassTakeQuizSubmitSuccessful: "TrainingClass-TakeQuiz-SubmitSuccessful",

    ChangePassword: "ChangePassword",

    TrainingClassTakeQuiz: "TrainingClass-TakeQuiz",
    HomeOutstandingTrainingNoData: "Home-OutstandingTraining-NoData",
    HomeUpcommingElectionsNoData: "Home-UpcommingElections-NoData",
    Payment: "Payment",

    Message: "Message",

    Top: "Top",

    WorkAssignmentList: "WorkAssignment-List",

    ApplicationNotificationPreferences: "Application-NotificationPreferences",

    HomeNews: "Home-News",

    Bottom: "Bottom",

    WorkAssignmentNoData: "WorkAssignment-NoData",

    CandidateNominate: "CandidateNominate",

    TrainingClassOnlineTraining: "TrainingClass-OnlineTraining",

    ApplicationLoadingPageForm: "Application-LoadingPage-Form",

    HomeNewWorkAssignmentsNoData: "Home-NewWorkAssignments-NoData",
    ForgotPasswordSendCode: "ForgotPassword-SendCode",
    ForgotPasswordEnterCode: "ForgotPassword-EnterCode",
    ForgotPasswordEnterNewPassword: "ForgotPassword-EnterNewPassword",

    WorkHistory: "WorkHistory",

    ContactUs: "ContactUs",

    MyProfileBasicInformation: "MyProfile-BasicInformation",

    TrainingMaterialList: "TrainingMaterial-List",

    HomeApply: "Home-Apply",
    RequiredDocumentsSubmit: "RequiredDocumentsSubmit",
    RequiredDocumentsSubmitSubmitSuccessful: "RequiredDocumentsSubmit-SubmitSuccessful",
    HomeOutstandingRequiredDocumentsNoData: "Home-OutstandingRequiredDocuments-NoData",
    ResetPasswordEnterNewPassword: "ResetPassword-EnterNewPassword",
}
